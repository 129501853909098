import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import Input from '../../components/Inputs/Input';
import SaveButton from '../../components/SaveButton';
import LoginAction from '../../actions/LoginAction';
import { Card, Form, Container, Col, } from 'react-bootstrap';
import Menu from '../../components/Menu';


import './style.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  onElementChange = (name, value) => {
    if (name === 'email') {
      this.setState({ email: value });
    } else {
      this.setState({ password: value });
    }
  };

  onSave = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    LoginAction.post(email, password);
    return false;
  };

  render() {
    const { isLoading, successCode } = this.props.login;
    const { email, password } = this.state;

    const errorClass = successCode === 2 && !isLoading ? 'error' : 'ok';

    if (successCode === 1 && !isLoading) {
      window.location.href = '/panel/calendar';
    }

    return (
      <Container>
        <Menu />
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
          <Form className="login-card" onSubmit={e => this.onSave(e)}>
            <h3>Zaloguj się</h3>
            <div className={`login-form ${errorClass}`}>
              <Col md={{ span: 10, offset: 1 }}>
                <Form.Group >
                  <Form.Label>Adres e-mail</Form.Label>
                  <Input
                    inputElement={{
                      value: email,
                    }}
                    onChange={value => this.onElementChange('email', value)}
                  />

                </Form.Group>
                <Form.Label>Hasło</Form.Label>
                <Input
                  inputElement={{
                    value: password,
                    type: 'password',
                  }}
                  onChange={value => this.onElementChange('password', value)}
                />

                <Card.Link className="forgot-pass-link" href="/forgot-password">Nie pamiętasz hasła?</Card.Link>

              </Col>

              <Col md={{ span: 10, offset: 1 }}>
                <SaveButton
                  isLoading={isLoading}
                  onSave={() => { }}
                  text="Zaloguj się"
                />
              </Col>
            </div>
          </Form>
        </Col>
      </Container >
    );
  }
}

const mapStateToProps = state => ({
  login: { ...state.login },
});


export default connect(mapStateToProps)(Login);
