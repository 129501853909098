import React, { Component } from 'react';
import { connect } from 'react-redux';
import Menu from '../../components/Menu';
import Notifications from '../Notifications';
import UserPart from '../RegisterForm/UserPart';
import FormsAction from '../../actions/FormsAction';
import Loader from '../../components/Loader';
import formSelector from '../../helpers/selectors/formSelector';
import UserAction from '../../actions/UserAction';
import SaveButton from '../../components/SaveButton';
import LinkButton from '../../components/Button/LinkButton';

class RegisterOnlyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsOk: false,
      invitation: {},
      isSaving: false,
      showSuccess: false,
    };

    FormsAction.registerForm('OnlyUserRegisterForm').then(() => {
      this.setState({ formIsOk: true });
    });

    UserAction.promiseGetInvitation(this.props.match.params.token)
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            invitation: response.data,
          });
          FormsAction.updateValue('OnlyUserRegisterForm', 'email', response.data.email);
          FormsAction.updateValue('OnlyUserRegisterForm', 'type', response.data.userType);
        }
      });
  }

  updateValue = (name, value) => {
    FormsAction.updateValue('OnlyUserRegisterForm', name, value);
  };

  onSaveHandler = () => {
    const { registerForm } = this.props;
    FormsAction.checkFormValidation('OnlyUserRegisterForm');
    if (registerForm.isValid) {
      this.setState({
        isSaving: true,
      });
      UserAction.promiseRegisterUserFromInvitation(
        this.props.match.params.token,
        registerForm,
      ).then((response) => {
        this.setState({
          isSaving: false,
        });
        if (response && response.status === 201) {
          this.setState({
            showSuccess: true,
          });
        }
      });
    }
  }

  render() {
    const { formIsOk, invitation, isSaving, showSuccess } = this.state;

    if (!formIsOk || !invitation.id) {
      return <Loader />;
    }

    const { registerForm } = this.props;

    if (showSuccess) {
      return (
        <div>
          <Menu />
          <div className="page-container">
            <h1 className="text-center">
              Zarejestruj konto
            </h1>
            <p>
              Konto zostało utworzone. Zaproszenie zrealizowane. Mozesz się teraz zalogować.
            </p>
            <LinkButton to="/login">
              Logowanie
            </LinkButton>
            <Notifications />
          </div>
        </div>
      );
    }

    return (
      <div>
        <Menu />
        <div className="page-container">
          <h1 className="text-center">
            Zarejestruj konto
          </h1>
          <UserPart
            registerForm={registerForm}
            onUpdateValue={this.updateValue}
          />
          <SaveButton
            variant="success"
            block
            isLoading={isSaving}
            onSave={this.onSaveHandler}
          />
          <Notifications />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: { ...state.user },
  registerForm: formSelector(state.forms, 'OnlyUserRegisterForm'),
});

export default connect(mapStateToProps)(RegisterOnlyUser);
